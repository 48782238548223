.dropdown {
    // position: relative;
    position: relative;
    display: inline-block;
    // background-color: var(--r-color-menu-bg-default);
    // box-shadow: var(--r-shadow-default);
    // padding: var(--r-spacing-xs) 0;
    // border-radius: var(--r-radius-large);
    // border: 1px solid var(--r-color-border);
  }
  
  .dropdown-content {
    // display: none;
    position: absolute;
    background-color:white;
    min-width: 300px;
    border: 1px solid var(--r-color-border);
    border-radius: var(--r-radius-large);
    padding: 12px 16px;
    z-index: 1;
    margin-top: 300px;
    margin-left: 300px;
  }
  
  .dropdown-content-open {
    display: block;
  }
  .dropdown-content-close {
    display: none;
  }