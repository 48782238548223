
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.p-flex{
  display: flex;
}
.p-flex-row{
  flex-direction: row;
}  
.p-flex-column{
  flex-direction: column;
}
.p-flex-wrap{
  flex-wrap: wrap;
} 
.p-mainaxis-center{
  justify-content: center;
}

.p-mainaxis-space-between{
  justify-content: space-between;
}

.p-crossaxis-center{
  align-items:center;
}

@for $i from 1 through 10 {
  $base: 10;
  $size: $base * $i;
  .p-margin-right-#{$size} { 
      margin-right: $size + 0px;
      }
  .p-margin-left-#{$size} { 
      margin-left: $size + 0px;
      }
  .p-margin-top-#{$size} { 
      margin-top: $size + 0px;
      }
  .p-margin-bottom-#{$size} { 
      margin-bottom: $size + 0px;
      }
}

.p-pointer:hover{
  cursor: pointer;
}

@for $i from 1 through 10 {
  $base: 10;
  $size: $base * $i;
  .p-padding-right-#{$size} { 
    padding-right: $size + 0px;
      }
  .p-padding-left-#{$size} { 
    padding-left: $size + 0px;
      }
  .p-padding-top-#{$size} { 
    padding-top: $size + 0px;
      }
  .p-padding-bottom-#{$size} { 
    padding-bottom: $size + 0px;
      }
}

.p-box{
  border: 1px solid var(--r-color-border);
}

.p-flex-column-gap{
  column-gap: 10px;
}

.p-flex-row-gap{
  row-gap: 10px
}

.content-area {
  overflow-y: auto;
  overflow-x: auto;
  max-height: 70vh;
}

.td-max {
  max-width: 550px;
  min-width: 350px;
}

.td-max2 {
  max-width: 450px;
  min-width: 200px;
}

.td-max-breakword {
  max-width: 50px;
  min-width: 350px;
  word-wrap: break-word;
}

.thead-fixed {
  position: sticky;
  top: 0; 
}

.th-vert {
  writing-mode: vertical-rl;
  text-align: end;
  vertical-align: middle;
  border: 1px solid rgba(224, 224, 224, 1);
}

.th-hor {
  border: 1px solid rgba(224, 224, 224, 1);
  min-width: 200px;
}

.td-hor{
  border: 1px solid rgba(224, 224, 224, 1);
}

.td-vert{
  border: 1px solid rgba(224, 224, 224, 1);
}

