.navbar_container{
  display: flex;
  background-color: var(--r-color-cyan-100);
  overflow: hidden;
  align-items: center;
  .logo{
    justify-content: left;
    margin-left: 5px;
  }
  .navbar_mini_container{
    display: flex;
    justify-content: right;
    flex-grow: 1;
    margin-right: 20px;
    align-items: center;
    align-self: center;
    .item{
      margin-left: 20px;
    }
  }
  .navbar{
    flex-grow: 4;
    display: flex;
    padding:10px;
    align-items: center;

    .item {
        border-style: none;
        color: #f2f2f2;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 17px;
        // box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
        cursor: pointer;
        outline: none;
        transition: 0.2s all;
        margin-left: 40px;

        // &:hover { 
        //   background-color: #0087c0;
        // }
      }
  }
}
